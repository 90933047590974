import React                from 'react'
import { map }              from 'lodash-es'
import {
    Container
}                           from 'react-bootstrap'
import RawHtml              from 'entrada-ui/RawHtml'
import Seo                  from 'components/Seo'
import Hero                 from 'components/Hero'
import TourGroup            from 'components/TourGroup'
import styles               from './styles.module.scss'

const TourGroupCard = TourGroup.CardWp

const GroupedToursList = ({
    pageContext: {
        data: {
            siteMetadata,
            slug,
            acf: {
                image,
                overview,
                page_heading
            },
            groups,
            yoast_meta
        }
    }
}) => {
    return (
        <>
            <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
            <Hero
                className={styles.hero}
                image={image.localFile.childImageSharp.fluid}
            >
                <h1 className={styles.heroTitle}>{page_heading}</h1>
            </Hero>

            <Container>
                <div className="text-center">
                    <RawHtml html={overview} />
                </div>

                <TourGroup>
                    {map(groups, (destination, idx) => (
                        <TourGroupCard
                            key={idx}
                            {...destination}
                        />
                    ))}
                </TourGroup>
            </Container>
        </>
    )
}

export default GroupedToursList
