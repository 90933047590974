import React                  from 'react'
import Img                    from "gatsby-image"
import {
    Col,
    Row
}                             from 'react-bootstrap'
import cx                     from 'utils/classnames'
import CardLabel              from 'components/CardLabel'
import Link                   from 'components/Link'
import styles                 from './styles.module.scss'

const TourGroup = ({ className, children, ...props }) => (
    <div className={cx(styles.container, className)} {...props}>
        <Row>
            {children}
        </Row>
    </div>
)

TourGroup.Card = React.forwardRef(function TourGroupCard(props, ref) {
    const {
        title,
        label,
        image,
        mapImage,
        url,
        totalTours = 0
    } = props

    return (
        <Col xs={12} sm={6} lg={4}>
            <div className={styles.root}>
                <Link to={url} className={styles.header}>
                    <Img
                        fluid={{
                            ...image,
                            aspectRatio: 425/283
                        }}
                    />
                    {mapImage &&
                    <div className={styles.map}>
                        <Img

                            fluid={{
                                ...mapImage,
                            }}
                        />
                    </div>
                    }
                    {label && label.color &&
                    <CardLabel text={label.text} />
                    }
                </Link>

                <h4 className={styles.title}><Link to={url}>{title}</Link></h4>
                {totalTours > 1 &&
                <span className={styles.helperText}>{totalTours} tours to choose from</span>
                }
            </div>
        </Col>
    )
})

TourGroup.CardWp = React.forwardRef(function TourGroupCardWp(props, ref) {
    const {
        url,
        acf: {
            card_title,
            card_label_text,
            card_label_color,
            card_image,
            card_map,
            related_tours
        },
        ...rest
    } = props


    return (
        <TourGroup.Card
            {...rest}
            ref={ref}
            title={card_title}
            label={card_label_text ? {
                text: card_label_text,
                color: card_label_color
            } : undefined}
            image={card_image.localFile.childImageSharp.fluid}
            mapImage={card_map ? card_map.localFile.childImageSharp.fluid : undefined}
            totalTours={related_tours.length}
            url={url}
        />
    )
})

export default TourGroup
